<template>
    <div class="wrapper">
        <div class="top">
            <Language/>
        </div>
        <div class="bottom">
            <WeChatQRCodeForm :type="'register'" @callback="callback">
                <template slot="title">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.weChatScanRegistration')}}</span>
                </template>
                <template slot="buttom">
                    {{$t('login.scanCodePublicRegistration')}}
                </template>
                <template slot="link">
                    <el-row style="text-align: center;">
                        <el-divider>{{$t('login.otherRegistrationMethods')}}</el-divider>
                        <el-col>
                            <div class="cuicon el-icon-mobile" style="background-color: #6478FF;" @click="()=>{
                                this.$router.replace('/login/register')
                            }"></div>
                        </el-col>
                    </el-row>
                </template>
            </WeChatQRCodeForm>
        </div>
    </div>
</template>

<script>
    import CommonLogin from "../../../utils/CommonLogin";
    import Language from "../../../components/login/Language";
    import WeChatQRCodeForm from "../../../components/login/WeChatQRCodeForm";

    export default {
        name: "RegisterWeChatQRCode",
        components: {WeChatQRCodeForm, Language},
        data(){
            return {

            }
        },
        mounted() {

        },
        methods: {
            callback(result){
                if (result){
                    this.$router.replace("/enterpriseInformation");
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @input-width: 400px;

    .wrapper {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 50px;
            padding-top: 7px
        }

        .bottom {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .cuicon{
        cursor: pointer;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 32px;
        color: white;
    }
</style>
